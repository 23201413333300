export const handleRemoveAd = ($container = document) => {
  const overlay = $container.querySelector(".g-ad-overlay");
  if (!overlay) return false;
  const overlayButton = overlay.querySelector(".g-ad-overlay__icon");

  const handleRemove = () => {
    overlay.remove();
    document.documentElement.style.setProperty("--ad-overlay-height", "0px");
  };

  overlayButton.addEventListener("click", handleRemove);
};
