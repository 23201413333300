"use strict";

import { accordionText } from "./lib/accordion-text";
import { animationMainVisual } from "./lib/animation-main-visual";
import { animationScaleIn } from "./lib/animation-scale-in";
import { initBarba } from "./lib/barba";
import { budoux } from "./lib/budoux";
import { checkAlphabet } from "./lib/check-alphabet";
import { checkAspectRatio } from "./lib/check-aspect-ratio";
import { checkEmpty } from "./lib/check-empty";
import { checkOnlyAlphabet } from "./lib/check-only-alphabet";
import { clipEyecatch } from "./lib/clip-eyecatch";
import { fileTypeInput } from "./lib/file-type-input";
import { initDrawer } from "./lib/handle-drawer";
import { initHeader } from "./lib/handle-header";
import { handlePostBanner } from "./lib/handle-post-banner";
import { handlePulldown } from "./lib/handle-pulldown";
import { handleRemoveAd } from "./lib/handle-remove-ad";
import { handleServiceTitle } from "./lib/handle-service-title";
import { handleTableOfContents } from "./lib/handle-table-of-contents";
import { setCustomProperties } from "./lib/set-custom-properties";

// 初回読み込み時に行う処理　最初にサイトを訪問した時のみ発火する処理
const initPage = () => {
  initBarba(handleLoadPage, handleAnimation);
  initHeader();
  initDrawer();
  handleLoadPage();
  handleAnimation();
};

// サイト読み込み時に行う共通の処理
const handleLoadPage = (container) => {
  /*
    サイト内遷移時にdocument.querySelector等で取得すると、
    遷移前と遷移後の二つのmainタグを取得するため遷移後のmainを取得する必要がある。
    containerは遷移後のmain、初回読み込み時はdocumentを代入
  */
  if (!container) container = document;

  // ライブラリ系はここで初期化する。
  checkEmpty(container);
  checkAspectRatio(container);
  checkAlphabet(container);
  checkOnlyAlphabet(container);
  accordionText(container);
  setCustomProperties(container);
  handleRemoveAd(container);
  handleTableOfContents(container);
  animationScaleIn(container);
  handlePulldown(container);
  budoux(container);
  clipEyecatch(container);
  handleServiceTitle(container);
  handlePostBanner(container);
  fileTypeInput();
};

// サイト読み込み時とサイト内遷移時のアニメーション
const handleAnimation = async (shutter, container) => {
  if (!container) container = document;
  const animationMV = new animationMainVisual(container);

  animationMV.phase1();
  (await shutter) && shutter(); // シャッターアニメーションが終わるのを待ってからフェーズ２が発火する
  animationMV.phase2();
};

window.addEventListener("DOMContentLoaded", initPage);
