import { loadDefaultJapaneseParser } from "budoux";
const parser = loadDefaultJapaneseParser();

// https://github.com/google/budoux/blob/main/javascript/README.md
export const budoux = (container) => {
  const targets = container.querySelectorAll("[data-budoux]");

  // spanのheightを[data-budoux]のheightに設定する
  const setTargetHeight = (target, span) => {
    if (span === null) return;
    target.style.minHeight = span.clientHeight + "px";
  };

  // [data-budoux]にあるテキストをbudouxで処理し、spanとして出力する。
  targets.forEach((target) => {
    target.innerHTML = parser.translateHTMLString(target.innerHTML);
    const span = target.firstChild;

    setTargetHeight(target, span);

    /*
      IOSの場合、メニューバーの表示・非表示時にもresizeイベントが発生するので、
      ウィンドウ幅が変更された時だけresizeイベントを発生させるように。
    */
    let lastInnerWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      // ウィンドウ幅が変化していないため、処理をキャンセルする。
      if (lastInnerWidth === window.innerWidth) return;

      lastInnerWidth = window.innerWidth;
      setTargetHeight(target, span);
    });
  });

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  // display: none の要素が表示されたときに
  const callback = (entries) => {
    for (const entry of entries) {
      const target = entry.target;
      const span = target.firstChild;

      if (entry.isIntersecting) {
        setTargetHeight(target, span);
      }
    }
  };

  const observer = new IntersectionObserver(callback, options);

  targets.forEach((target) => observer.observe(target));
};
