export const clipEyecatch = (container) => {
  const targets = container.querySelectorAll('[data-head="root"]');
  if (!targets.length) return false;

  const callback = (entry) => {
    const cloneEl = entry.target.closest('[data-head="root"]').querySelector('[data-head="title-wrap"].js-clone-node');
    cloneEl.style.minWidth = entry.target.clientWidth + "px";
  }

  let resizeObserver = new ResizeObserver((entries) => entries.forEach((entry) => callback(entry)));

  targets.forEach((target) => {
    const eyecatch = target.querySelector('[data-head="eyecatch"]');
    const titleWrap = target.querySelector('[data-head="title-wrap"]');
    const titleWrapClone = titleWrap.cloneNode(true);

    titleWrap.classList.add('js-original-node');
    titleWrapClone.classList.add('js-clone-node');
    titleWrapClone.style.minWidth = titleWrap.clientWidth + "px";
    eyecatch.append(titleWrapClone);
    resizeObserver.observe(titleWrap)
  })
}
