import anime from "animejs";

export class animationMainVisual {
  constructor(container) {
    this.image = container.querySelector('[data-animation="mv-image"]');
    this.title = container.querySelector('[data-animation="mv-title"]');
    this.titles = container.querySelectorAll('[data-animation="mv-titles"]');
  }

  // image animation
  imageAnimation = () => {
    return anime({
      targets: this.image,
      delay: 250,
      duration: 1200,
      easing: "easeOutQuad",
      scale: [1.06, 1],
    });
  };

  // title animation
  titleAnimation = () => {
    return anime({
      targets: this.title,
      delay: 0,
      duration: 500,
      easing: "easeOutQuad",
      opacity: [0, 1],
    });
  };

  // titles animation
  titlesAnimation = () => {
    return anime({
      targets: this.titles,
      delay: anime.stagger(50, { start: 0 }),
      duration: 500,
      easing: "easeInOutSine",
      opacity: [0, 1],
      translateY: ["2.5px", 0],
    });
  };

  phase1 = () => {
    this.imageAnimation().play();
    // pause()はアニメーション前のスタイルを反映させる処理
    this.titleAnimation().pause();
    this.titlesAnimation().pause();
  };

  phase2 = () => {
    this.titleAnimation().play();
    this.titlesAnimation().play();
  };
}
