import barba from "@barba/core";

export const handleSelect = (container) => {
  const targets = container.querySelectorAll("[data-select]");
  if (targets.length === 0) return false;
  targets.forEach((target) => {
    target.onchange = () => {
      barba.go(target.value);
    };
  });

  const wraps = container.querySelectorAll("[data-select-wrap]");
  if (wraps.length === 0) return false;
  wraps.forEach((wrap) => {
    [...wrap.querySelector("[data-select]").options].map((option) => {
      if (option.hasAttribute("selected")) {
        wrap.querySelector("[data-select-label]").textContent = option.textContent;
      }
    });
  });
};

export const handlePulldown = (container) => {
  // selectの遷移処理
  const selects = container.querySelectorAll('[data-pulldown="select"]');
  if (selects.length === 0) return false;
  selects.forEach((select) => {
    select.onchange = () => {
      barba.go(select.value);
    };
  });

  // safariでテキストが中央に寄らないため、テキスト用のDOMを用意
  const wrappers = container.querySelectorAll('[data-pulldown="wrapper"]');
  if (wrappers.length === 0) return false;
  wrappers.forEach((wrapper) => {
    [...wrapper.querySelector('[data-pulldown="select"]').options].map((option) => {
      if (option.hasAttribute("selected")) {
        wrapper.querySelector('[data-pulldown="label"]').textContent = option.textContent;
      }
    });
  });
};
