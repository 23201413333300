import barba from "@barba/core";
import anime from "animejs/lib/anime.es.js";
import { addClassAspectRatio } from "./check-aspect-ratio";

export const initHeader = async () => {
  const header = document.querySelector('[header="root"]');
  if (header === null) return;
  const menu = header.querySelector('[header="menu"]');
  if (menu === null) return;
  const CLASS_SHOW_STICKY = "js-header__menu--sticky";
  const logo = header.querySelector('[header="logo"]');

  // アニメーションの共通設定
  const animeOptions = {
    delay: 0,
    duration: 250,
    easing: "easeInOutQuad",
  };

  // 最初のセクションのdata属性の色を、メニューのフォントカラーにする
  const setColorHeaderMenu = (container = document) => {
    const firstSection = container.querySelector('[data-barba="container"] > section');
    // セクションがない、またはdata-intersectionがない場合、処理を終了します。
    if (firstSection === null || firstSection.dataset.intersection === undefined) return;
    const getColorFirstSection = firstSection.dataset.intersection;
    header.dataset.intersectionHeader = getColorFirstSection;
  };

  // ヘッダーの高さをcss変数に設定
  const setPropertyHeaderHeight = () => {
    document.documentElement.style.setProperty("--header-height", header.clientHeight + "px");
  };

  // ヘッダーの幅または高さが変わるたびに実行する
  const headerResizeObserver = new ResizeObserver(() => {
    // 幅を正確に取得するためのディレイが必要
    setTimeout(setPropertyHeaderHeight, 100);
  });

  // メニューを固定する
  const hideSticky = () => {
    if (!menu.classList.contains(CLASS_SHOW_STICKY)) return;

    anime({
      ...animeOptions,
      targets: menu,
      opacity: [1, 0],
      complete: () => {
        menu.classList.remove(CLASS_SHOW_STICKY);

        anime({
          ...animeOptions,
          // ロゴがない場合がある
          targets: [menu, logo !== null ? logo : ""],
          opacity: [0, 1],
        });
      },
    });
  };

  // メニューをスティッキーさせる
  const showSticky = () => {
    menu.classList.add(CLASS_SHOW_STICKY);
    // ロゴがある場合
    if (logo !== null) logo.style.opacity = 0;

    anime({
      ...animeOptions,
      targets: menu,
      opacity: [0, 1],
    });
  };

  // メニューをスティッキーにする判定の処理
  const handleSticky = () => {
    let timeoutID = 0;

    const callback = (entries) => {
      entries.forEach((entry) => {
        // 前回のtimeoutを終了（ある場合）
        clearTimeout(timeoutID);

        // 前回のアニメーションを終了（ある場合、チラツキの原因になる）
        anime.remove(menu);

        // 動作が連続すると不具合を起こすため遅延させる
        timeoutID = setTimeout(() => {
          if (entry.isIntersecting) {
            hideSticky();
          } else {
            showSticky();
          }
        }, 100);
      });
    };

    const observerOptions = {
      rootMargin: "0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(callback, observerOptions);
    const intersectionEl = document.createElement("div");
    intersectionEl.classList.add("js-header__intersection");
    header.appendChild(intersectionEl);
    observer.observe(intersectionEl);
  };

  // 初期状態で非表示のヘッダーを表示する
  const showHeader = () => {
    anime({
      ...animeOptions,
      targets: header,
      opacity: [0, 1],
    });
  };

  // ロゴの幅と高さを取得する
  const logoLoading = () => {
    return new Promise(async (resolve) => {
      // クラスがつく処理を待つ
      await addClassAspectRatio(logo);
      resolve();
    });
  };

  // ロゴがあれば
  if (logo !== null) {
    await logoLoading();
    headerResizeObserver.observe(header);

    // リンク切れが発生する場合に追加を検討する処理
    // リクエストを送信し、画像の有無を確認する
    // await fetch(logo.src, {
    //   method: "GET",
    // })
    //   .then(async (response) => {
    //     if (!response.ok) {
    //       throw new Error(response.statusText);
    //     }

    //     await logoLoading();
    //     headerResizeObserver.observe(header);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     logo.parentNode.remove();
    //   });
  }

  // 初回読み込み時に実行する
  setColorHeaderMenu();
  handleSticky();

  // ページ内移動時に再実行する
  barba.hooks.after((data) => {
    setColorHeaderMenu(data.next.container);
  });

  // すべての処理が完了したらヘッダーを表示する
  showHeader();
};
