import anime from "animejs/lib/anime.es.js";

// テキスト用アコーディオン
export const accordionText = (container) => {
  const textContents = container.querySelectorAll(".g-accordion");
  const textContentsOpenClass = "g-accordion--open";
  const textContentsCloseClass = "g-accordion--close";

  textContents.forEach(($item) => {
    const $target = $item.querySelector("p");
    const $button = $item.querySelector(".g-accordion__button");
    const rows = Number(getComputedStyle($target).getPropertyValue("-webkit-line-clamp")); // 制限する行数

    // テキスト要素の高さを更新
    const textHeightUpdate = () => {
      // テキスト要素の高さを取得
      let textHeight = $target.scrollHeight;
      // テキスト要素のline-heightを取得
      let lineHeight = getComputedStyle($target).getPropertyValue("line-height");
      // [32.4px]のようなピクセル値が返ってくるので、数字だけにする
      lineHeight = lineHeight.replace(/[^-\d\.]/g, "");

      // rows行で制限、でrows行から３行以上隠れる場合に「view all」
      if (textHeight > lineHeight * (rows + 3)) {
        // 指定行数以上
        $item.classList.add(textContentsCloseClass);
        $button.style.display = "";
        window.addEventListener("resize", textHeightUpdate);
        return false;
      } else {
        // 指定行数以下
        $item.classList.remove(textContentsCloseClass);
        $button.style.display = "none";
        window.removeEventListener("resize", textHeightUpdate);
        return false;
      }
    };

    const buttonAnime = () => {
      anime({
        targets: $button,
        delay: 0,
        duration: 250,
        easing: "easeInOutQuad",
        opacity: [1, 0],
        complete: () => {
          $button.style.display = "none";
          $item.classList.replace(textContentsCloseClass, textContentsOpenClass);
        },
      });
    };

    textHeightUpdate();

    // Moreボタンを押したら、テキスト要素のheightを変更
    $button.addEventListener("click", () => {
      buttonAnime();
      window.removeEventListener("resize", textHeightUpdate);
    });
  });
};
