// 一度きりの処理の設定
const option = {
  once: true,
};

export const animationShutter = {
  // 遷移前のアニメーション
  in: () => {
    const shutter = document.getElementById("js-shutter");
    const whiteoutTime = 0; // ホワイトアウト秒数

    return new Promise((resolve) => {
      if (!shutter) return resolve();

      shutter.classList.add("js-shutter--in");

      // 最後にアニメーション終わる要素がfirst-child
      shutter.querySelector(".l-shutter__line:first-child").addEventListener("transitionend", () => setTimeout(resolve, whiteoutTime), option);
    });
  },

  // 遷移後のアニメーション
  out: () => {
    return new Promise((resolve) => {
      const shutter = document.getElementById("js-shutter");

      if (!shutter) return resolve();

      shutter.classList.add("js-shutter--out");
      shutter.querySelector(".l-shutter__line:first-child").addEventListener(
        "transitionend",
        () => {
          shutter.className = "l-shutter";
          resolve();
        },
        option
      );
    });
  },
};
