export const handlePostBanner = (container) => {
  const post = container.querySelector("[data-article]");
  if (!post) return; // postがなければ中断
  post.querySelectorAll("[data-article] > a").forEach((anchor) => {
    // ogp等が含まれないようにclassを持っているaを除外
    if (anchor.className !== "") return;
    // img or pictureを持っていないaを除外
    if (anchor.querySelectorAll("img, picture").length === 0) return;

    anchor.classList.add("js-post-banner");

    // aに横幅があれば終了
    if (anchor.clientWidth !== 0) return;

    // 画像を読み込み横幅を取得して設定
    anchor.querySelectorAll("img, picture img").forEach((img) => {
      const image = new Image();
      image.src = img.src;
      image.onload = () => {
        img.style.width = image.width + "px";
      };
    });
  });
};
