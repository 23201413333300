// アス比によって付けるクラス
const CLASS_ASPECT_RATIO = {
  HORIZONTAL: "js-length--horizontal",
  VERTICAL: "js-length--vertical",
  SQUARE: "js-length--square",
};

// アス比によって付けるクラスを分ける
const handleClassToAspectRatio = (target) => {
  const width = target.clientWidth;
  const height = target.clientHeight;
  const ratio = 0.85;
  if (width === 0 || height === 0) return;

  // 初期化
  target.classList.remove(CLASS_ASPECT_RATIO.HORIZONTAL, CLASS_ASPECT_RATIO.VERTICAL, CLASS_ASPECT_RATIO.SQUARE);

  // ration = width or height の数字が小さい方 / width or height の数字が大きい方
  if (width > height && height / width < ratio) {
    target.classList.add(CLASS_ASPECT_RATIO.HORIZONTAL);
  } else if (height > width && width / height < ratio) {
    target.classList.add(CLASS_ASPECT_RATIO.VERTICAL);
  } else {
    target.classList.add(CLASS_ASPECT_RATIO.SQUARE);
  }
};

// 画像の読み込みを待ってからクラスを付ける
export const addClassAspectRatio = (target) => {
  return new Promise(async (resolve) => {
    const img = new Image();
    img.src = target.src;
    // 画像の読み込みを待つ
    img.onload = () => {
      handleClassToAspectRatio(target);
      resolve();
    };
    img.addEventListener("error", resolve);
  });
};

export const checkAspectRatio = (container) => {
  const targets = container.querySelectorAll("[data-article] > picture > img");

  targets.forEach(async (target) => {
    // クラスがつく処理
    addClassAspectRatio(target);
  });
};
