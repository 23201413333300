export const checkOnlyAlphabet = (container) => {
  const targets = container.querySelectorAll("[data-only-alphabet]");
  const regexp = /^[a-zA-Z0-9!-/:-@¥[-`{-~\s]*$/;

  targets.forEach((target) => {
    const text = target.textContent;
    if (text === null || regexp.test(text)) {
      target.dataset.onlyAlphabet = "true";
    }
    return false;
  });
};
