// 子要素の有無をチェック
export const checkEmpty = (container) => {
  const $items = container.querySelectorAll("[data-check-empty]");
  const config = {
    childList: true,
  };

  $items.forEach(($item) => {
    // devtool上でも反映する
    const observer = new MutationObserver(function () {
      if ($item.children.length === 0) {
        $item.classList.add("js-empty");
      } else {
        $item.classList.remove("js-empty");
      }
    });
    observer.observe($item, config);

    if ($item.children.length === 0) {
      $item.classList.add("js-empty");
    }
  });
};
