// <input type="file" /> でアップロードされたファイルを操作する https://qiita.com/jkr_2255/items/1c30f7afefe6959506d2
export const fileTypeInput = () => {
  const targets = document.querySelectorAll("[data-file-type-input]");

  // ファイル名と削除ボタンが含まれるDOMを作成
  const createFileItem = () => {
    const div = document.createElement("div");
    div.classList.add("c-input--file-item");
    return div;
  };

  // ファイル名のDOMを作成
  const createFileName = (fileName, fileSize) => {
    const span = document.createElement("span");

    // ファイルサイズを四捨五入する
    const roundedFileSize = Math.round((fileSize / 1024 / 1024) * 100) / 100;
    span.classList.add("c-input--file-name");
    span.textContent = fileName + "[" + roundedFileSize + "MB]";
    return span;
  };

  // 項目を削除するボタンのDOMを作成
  const createFileDeleteButton = (callback) => {
    const button = document.createElement("button");
    button.classList.add("c-input--file-delete");
    button.textContent = "削除";
    button.type = "button";
    button.addEventListener("click", callback);
    return button;
  };

  targets.forEach((target) => {
    const input = target.querySelector('input[type="file"]');
    if (input === null) return false;

    // inputでアップロードされたファイルを管理するオブジェクト
    let dataTransfer = new DataTransfer();

    // data-is-uploadedのフラグを制御する
    const setIsUploaded = (boolean) => {
      target.dataset.isUploaded = boolean;
    };

    // inputにファイルがアップロードされた時
    input.addEventListener("change", () => {
      const { files } = input;
      if (files === null) return false;

      // data-is-uploaded="true" にする
      setIsUploaded(true);

      // ファイルの数だけループ
      for (let i = 0; i < files.length; i++) {
        const fileItem = createFileItem();
        const fileName = createFileName(files[i].name, files[i].size);
        const fileDeleteButton = createFileDeleteButton(() => {
          // このファイルを最新のファイル一覧から削除する
          dataTransfer.items.remove(files[i]);

          // 最新のファイル一覧に更新する
          input.files = dataTransfer.files;

          // この項目を削除する
          fileItem.remove();

          // アップロードしたファイルがなければ、data-is-uploaded="false" にする
          if (input.files.length === 0) {
            setIsUploaded(false);
          }
        });

        // このファイルを最新のファイル一覧に追加する
        dataTransfer.items.add(files[i]);

        // 各 DOM をレンダリングさせる。
        fileItem.appendChild(fileName);
        fileItem.appendChild(fileDeleteButton);
        target.appendChild(fileItem);
      }

      return false;
    });
  });
};
