import anime from "animejs/lib/anime.es.js";

export const handleTableOfContents = (container) => {
  const targets = container.querySelectorAll('.g-table-of-contents__link[href^="#"]');
  const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;

  targets.forEach((target) => {
    target.addEventListener("click", (e) => {
      const id = target.getAttribute("href");
      const headerHeight = document.querySelector(".l-header").clientHeight;
      const scrollPos = container.querySelector(id).offsetTop - headerHeight;

      e.stopPropagation();
      e.preventDefault();
      history.replaceState("", "", id);
      anime({
        targets: scrollElement,
        delay: 0,
        duration: 250,
        easing: "easeInOutQuad",
        scrollTop: scrollPos,
      });
    });
  });
};
