export const handleServiceTitle = (container) => {
  const list = container.querySelector('[data-service="list"]');
  if (!list) return false;
  const items = list.querySelectorAll('[data-service="item"]');
  const mediaQuery = (breakPoint) => window.matchMedia(`screen and (min-width: ${breakPoint})`).matches;

  const handleServiceTitle = () => {
    let column = 0;

    // 高さを初期化
    items.forEach((target) => (target.style.height = ""));

    //　画面幅によってカラム数を指定
    if (mediaQuery("1280px")) {
      // PCワイド
      column = 3;
    } else if (mediaQuery("767px")) {
      // PCナロー
      column = 2;
    } else {
      // SP
      return false;
    }

    const array = [...Array(items.length).keys()];
    const arrayCount = Math.ceil(array.length / column);
    let sliceArrays = [];

    // 配列をカラム数で分ける
    for (let i = 1; i <= arrayCount; i++) {
      let increment = column * i;
      sliceArrays = [...sliceArrays, array.slice(increment - column, increment)];
    }

    // 一列ごとに最長のheightに揃える
    sliceArrays.forEach((sliceArray) => {
      let height = 0;

      sliceArray.forEach((index) => {
        if (items[index].clientHeight > height) {
          height = items[index].clientHeight;
        }
      });

      sliceArray.forEach((index) => {
        items[index].style.height = height + "px";
      });
    });

    return false;
  };

  const options = {
    characterData: true,
    childList: true,
    subtree: true,
  };

  const mutationObserver = new MutationObserver(handleServiceTitle);
  items.forEach((item) => mutationObserver.observe(item, options));

  handleServiceTitle();
  window.addEventListener("resize", handleServiceTitle);
  return false;
};
