import barba from "@barba/core";
import anime from "animejs/lib/anime.es.js";

// ボディを固定するクラス
const CLASS_FIXED_SCROLL = "js-fixed-scroll";

// ドロワーを開いたときにposition:stickyを固定する処理
const handlePositionSticky = () => {
  const target = document.querySelector(".p-history__sticky");
  if (target === null) return;
  const parent = target.parentNode;
  if (parent === null) return;

  target.removeAttribute("style");
  parent.removeAttribute("style");

  if (document.body.classList.contains(CLASS_FIXED_SCROLL)) return;

  // 高さを固定するため親への指定が先
  parent.style.cssText = `width: ${parent.clientWidth}px; height: ${parent.clientHeight}px`;
  target.style.cssText = `position: fixed; top: ${target.getBoundingClientRect().top}px; width: ${parent.clientWidth}px; height: ${parent.clientHeight}px`;
};

// スクロール位置を保存する
let bodyScrollPosition = 0;

// ドロワーを開いた時にボディを固定する処理
const drawerOpenedBody = () => {
  handlePositionSticky();
  bodyScrollPosition = window.scrollY;
  document.body.classList.add(CLASS_FIXED_SCROLL);
  document.body.style.top = `-${bodyScrollPosition}px`;
};

// ドロワーを閉じた時にボディを固定解除する処理
const drawerClosedBody = () => {
  handlePositionSticky();
  document.body.classList.remove(CLASS_FIXED_SCROLL);
  document.body.style.top = "";
  window.scrollTo(0, bodyScrollPosition);
};

// ドロワー処理
export const initDrawer = () => {
  const openButtons = document.querySelectorAll("[data-drawer='open']");
  const closeButton = document.querySelector("[data-drawer='close']");
  const drawer = document.querySelector("[data-drawer='root']");
  const menu = document.querySelector("[data-drawer='menu']");
  const overlay = document.querySelector("[data-drawer='overlay']");
  const CLASS_SHOW_DRAWER = "js-drawer--show";

  if (drawer === null || menu === null) return;

  // 共通のアニメーション設定
  const animeOptions = {
    delay: 0,
    easing: "easeInOutQuad",
    duration: 150,
  };

  // ドロワー開くアニメーション
  const animationOpenDrawer = () => {
    anime
      .timeline(animeOptions)
      .add({
        targets: overlay,
        opacity: [0, 1],
        begin: () => {
          drawerOpenedBody();
          drawer.classList.add(CLASS_SHOW_DRAWER);
        },
      })
      .add({
        targets: menu,
        opacity: [0, 1],
        translateX: [5, 0],
      });
  };

  // ドロワー閉じるアニメーション
  const animationCloseDrawer = () => {
    return new Promise((resolve) => {
      anime
        .timeline(animeOptions)
        .add({
          targets: menu,
          opacity: [1, 0],
          translateX: [0, 5],
        })
        .add({
          targets: overlay,
          opacity: [1, 0],
          complete: () => {
            // ドロワー内のスクロール位置をリセット
            menu.scrollTop = 0;
            drawer.classList.remove(CLASS_SHOW_DRAWER);
            drawerClosedBody();
            resolve();
          },
        });
    });
  };

  // ドロワー内のaタグ処理
  const handleDrawerAnchor = async (target) => {
    const targetUrl = new URL(target.href);
    // 遷移先がエラーページの場合につく特殊なクラスを付いているか
    const barbaPrevent = target.classList.contains("js-prevent-barba");

    // 外部リンクに対応する処理 https://q-az.net/javascript-local-links/
    const isLinkSameDomain = () => {
      const reg = new RegExp("^(https?:)?//" + document.domain);
      // リンク先が現在と同じ同じドメイン
      return targetUrl.href.match(reg) || targetUrl.href.charAt(0) === "/";
    };

    // リンク先が現在と同じページ
    const isLinkSamePage = () => {
      return isLinkSameDomain() && targetUrl.pathname === location.pathname;
    };

    // target="_blank"の処理
    if (target.getAttribute("target") === "_blank") {
      await animationCloseDrawer();
      open(targetUrl.href);
      return;
    }

    // 現在と同じページのリンクのクリックすると何も起きないので処理を追加
    if (isLinkSamePage() && !barbaPrevent) {
      // #付きのリンクかで処理を分ける
      if (targetUrl.hash === "") {
        await animationCloseDrawer();
        window.scrollTo(0, 0);
      } else {
        location.hash = "";
        await animationCloseDrawer();
        location.hash = targetUrl.hash.slice(1);
      }
      return;
    }

    //内部リンク時の処理
    if (isLinkSameDomain() && !barbaPrevent) {
      await animationCloseDrawer();
      barba.go(targetUrl.href);
      return;
    }

    await animationCloseDrawer();
    location.href = targetUrl.href;
  };

  // 開くボタンをクリックした時
  openButtons.forEach((openButton) => {
    openButton.addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
      animationOpenDrawer();
    });
  });

  // 閉じるボタンをクリックした時
  if (closeButton !== null) {
    closeButton.addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
      animationCloseDrawer();
    });
  }

  // ドロワー背景をクリックした時
  drawer.addEventListener("click", animationCloseDrawer);

  // ドロワー内リンクをクリックした時
  drawer.querySelectorAll("a").forEach((target) => {
    target.addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
      handleDrawerAnchor(target);
    });
  });
};
