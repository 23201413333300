export const animationScaleIn = (container) => {
  const targets = container.querySelectorAll('[data-animation="scale-in"]');

  const callback = (entries, obs) => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) return false;
      entry.target.classList.add('js-animation-scale-in');
      obs.unobserve(entry.target)
    })
  }

  const options = {
    rootMargin: '0px',
    threshold: 0.1, // 0は要素が1pxでも画面に入ったら発火。 50% = 0.5
  }

  const observer = new IntersectionObserver(callback, options);

  targets.forEach(target => {
    observer.observe(target);
  })
}
