export const checkAlphabet = (container) => {
  const targets = container.querySelectorAll("[data-string]");

  const handleCheckAlphabet = (target) => {
    const currentNode = document.createNodeIterator(target, NodeFilter.SHOW_TEXT).nextNode();

    const alphabet = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/; //半角全角英数字記号
    let str = target.textContent;
    if (target.textContent === "") return false; // 空文字なら処理を終了する
    str = str.replace(/\s+/g, "");
    if (alphabet.test(str)) {
      currentNode.parentElement.dataset.string = "alphabet";
    }
  };

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio <= 0) return;
        handleCheckAlphabet(entry.target);
        observer.unobserve(entry.target);
      });
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    }
  );

  targets.forEach((target) => {
    handleCheckAlphabet(target);
    observer.observe(target);
  });
};
